import './App.css';
import Footer from './components/Footer';
import Hero from './components/Hero';
import MusicListing from './components/MusicListing';
import SpotifyPlayer from './components/SpotifyPlayer';


function App() {
  return (
    <>
  <Hero />
  <SpotifyPlayer />
  <MusicListing />
  <Footer />


    </>
  );
}

export default App;

import React from "react";
import logo from "./assets/images/Sovarozum Logo - BlackText.png";
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
const Hero = () => {
  return (
    <div className="grid bg-slate-100 justify-items-center lg:grid-cols-2">
      <img src={logo} alt="sovarozum logo" className=" flex px-8 pt-16 lg:p-12 lg:mt-24 place-items-center" />

      <div className="grid place-items-center grid-rows-1 text-black py-16" >
        
          <p className="text-md px-16 py-0">
          <h1 className="text-3xl">Music Composer & Media Scorer</h1> <br />
            Welcome to my canvas, 'Sovarozüm' (the name means 'Owl Sense' in Czech - if you separate the words (I think it sounds cool!)). 
            
            <br /><br />I write (compose) music in various genres. I tend to make fantasy or electronic synth music, but I like to dabble and create music that I find interesting. <br/> <br />
          
          I play saxophone, guitar, and a little piano (enough to get chords out). However, I prefer to write songs more often than play them. <br /> <br />  
          I've been writing music by-hand and in DAWs for more than 10 years. I've produced 4 full-length albums and you can find my music in different corners of the internet!
          <br /><br />
          Listen to some songs below and check me out on other social media platforms. You can also find me on most major music streaming platforms! 
          <br />
          <br />
          <div className="grid">
          <span className="bg-gp-cyan text-white rounded p-4 mb-2 text-center w-64"><AnchorLink href="#platforms">Streaming Platforms</AnchorLink></span>
          <div className="grid grid-cols-2">
          <button className="text-sm bg-blue-100 text-black rounded p-4 my-4 mr-2 hover:bg-gp-maxblue basis-1/2"> <a href="https://gonpulvo.com">(My muse, 'gon pulvo')</a></button>
          <button className="text-2xl bg-sova-purple text-white  rounded p-4 my-4 mx-2 hover:bg-sova-royalblue basis-1/2"> <a href="https://shop.sovarozum.com">SHOP</a></button>
          </div>
          </div>
      
          </p>
          
        </div>  
      </div>
  );
};

export default Hero;

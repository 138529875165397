import React from "react";
import SpotifyPlayerEmbed from "react-spotify-player";

const SpotifyPlayer = () => {
  const albums = {
    pretium:
      "https://open.spotify.com/album/1fWPtbJ26gPAOJpKJfI46P?si=FpD3W_YhSh-KHmp3pf9ujw",
    ansible:
      "https://open.spotify.com/album/1mpigHfjbqMKCoFuwqeioM?si=m6Lx9GUESZ6wQ-o1IQDMEw",
    visionaries:
      "https://open.spotify.com/album/2sRGJ8Etg6v940VbWUz9iV?si=LKCw3gKFQv-1UVSojsgyvA",
    aviator:
      "https://open.spotify.com/album/2n2NxfuqHnsnAc02zZFupz?si=fu8AwCUoTIGve-lfu2lUjg",
  };

  const songRandomizer = (urls) => {
    // Create array of object keys, ["311", "310", ...]
    const keys = Object.keys(urls);

    // Generate random index based on number of keys
    const randIndex = Math.floor(Math.random() * keys.length);

    // Select a key from the array of keys using the random index
    const randKey = keys[randIndex];

    // Use the key to get the corresponding name from the "names" object
    const name = urls[randKey];
    // console.log(name)
    return name;
  };

  const size = {
    width: "60%",
    height: 300,
  };
  return (
    <div className="py-8 flex justify-center bg-sova-yellow">
      <SpotifyPlayerEmbed
        uri={songRandomizer(albums)}
        theme={"black"}
        view={"coverart"}
        size={size}
      />
    </div>
  );
};

export default SpotifyPlayer;
